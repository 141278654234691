/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  /* --dark-purple: #131330; */
  --dark-purple: #100530;
  --light-purple: #5b42f3;
  --light-gray: #e8e7e7;
  --dark-blue: #1a598f;
  --purple: #8c52ff;
  --aqua: #38b6ff;
  --orange: #f16529;
  --shadow-color: 0deg 0% 71%;
  --shadow-elevation-low: 0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.08),
    0.4px 0.9px 1.4px -0.4px hsl(var(--shadow-color) / 0.2),
    0.9px 1.8px 2.8px -0.7px hsl(var(--shadow-color) / 0.32);
  --shadow-elevation-medium: 0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.08),
    1px 2px 3.1px -0.2px hsl(var(--shadow-color) / 0.18),
    2.1px 4.1px 6.4px -0.5px hsl(var(--shadow-color) / 0.27),
    4.4px 8.8px 13.7px -0.7px hsl(var(--shadow-color) / 0.37);
  --shadow-elevation-high: 0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.08),
    1.8px 3.5px 5.5px -0.1px hsl(var(--shadow-color) / 0.12),
    3.1px 6.3px 9.7px -0.2px hsl(var(--shadow-color) / 0.16),
    4.7px 9.3px 14.5px -0.3px hsl(var(--shadow-color) / 0.21),
    6.7px 13.4px 20.8px -0.4px hsl(var(--shadow-color) / 0.25),
    9.5px 19px 29.5px -0.5px hsl(var(--shadow-color) / 0.3),
    13.5px 27px 41.9px -0.6px hsl(var(--shadow-color) / 0.34),
    19px 38px 58.9px -0.7px hsl(var(--shadow-color) / 0.38);
}

body {
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Arial";
  overflow-x: hidden;
  color: rgb(162, 162, 162);
  letter-spacing: 0.5px;
  padding-right: 0px !important;
  width: 100vw;
}

h1,
h2,
h3,
h4,
h5 {
  color: black;
}

a {
  cursor: pointer;
}

.color-white {
  color: white;
}

.bigBtn {
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border: 0;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  font-family: "Arial";
  font-size: 16px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.bigBtn:active,
.bigBtn:hover {
  outline: 0;
}

.bigBtn span {
  background-color: rgb(5, 6, 45);
  padding: 10px 20px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.bigBtn:hover span {
  background: none;
}

.btn {
  appearance: none;
  border: none;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border-radius: 8px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "Arial";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  max-width: 100%;
  min-width: 140px;
  outline: none;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 400ms cubic-bezier(0.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform;
  z-index: 1000;
}

.btn:disabled {
  pointer-events: none;
}

.btn:hover {
  box-shadow: rgba(91, 66, 243, 0.5) 0 8px 15px;
  transform: translateY(-2px);
}

.btn:active {
  box-shadow: none;
  transform: translateY(0);
}

.text-center {
  text-align: center;
}

.box-center {
  display: flex;
  flex-direction: column;
}

.specialization {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  background-color: var(--dark-purple);
}

.padding {
  padding-bottom: 60px;
}

.specialization h2 {
  color: white;
}

.specialization .grid {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 80%;
  padding: 3rem;
  justify-content: center;
}

.grid .card {
  background: #fff;
  padding: 2rem;
  width: 30%;
  position: relative;
  border-radius: 5px;
}

.card .image {
  width: 18%;
  position: absolute;
  top: -40px;
  margin: auto;
  text-align: center;
  left: 0;
  right: 0;
}

.card .title {
  margin-top: 2rem;
  font-size: 16px;
  color: black;
  font-weight: bold;
}

.learning_path_title {
  text-align: center;
  font-family: "Arial";
  font-size: 41px;
  font-weight: normal;
}

.sub_container {
  padding: 80px;
}

.learning_path_layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.learning_path_card {
  background-color: white;
  width: 30%;
  height: 180px;
  box-shadow: 0 1.5em 2.5em -0.5em rgba(0, 0, 0, 0.2);
  transition: transform 0.45s ease, background 0.45s ease;
  border-radius: 10px;
  margin: 10px;
  display: flex;
  padding: 10px;
}

.learning_path_card:hover {
  cursor: pointer;
  box-shadow: 0 1.5em 3.5em 0em rgba(0, 0, 0, 0.2);
  transition: transform 0.45s ease, background 0.45s ease;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.learning_path_card img {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.learning_card_sublayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
  padding-left: 10px;
  width: 50%;
}
.card_sublayout {
  display: flex;
  flex-direction: column;
}

.card_title {
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
  color: black;
}

.card_subtitle {
  color: gray;
  font-size: 16px;
}

.card_description {
  color: gray;
  font-size: 14px;
  margin-top: 10px;
}

.card_footer {
  font-size: 18px;
  font-weight: lighter;
}

.card button {
  border: none;
  background-color: white;
  border-radius: 5px;
  box-shadow: var(--shadow-elevation-medium);
  display: flex;
  align-items: center;
  padding: 0px;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  margin-top: 1rem;
  color: black;
}

.card button:hover {
  cursor: pointer;
  box-shadow: rgba(247, 248, 249, 0.423) 0 8px 15px;
  transform: translateY(-2px);
}

.card .pinkSpan {
  background-color: rgb(249, 63, 69);
  width: 20px;
  height: 60px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.card .greenSpan {
  background-color: rgb(111, 213, 61);
  width: 20px;
  height: 60px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.card .yellowSpan {
  background-color: rgb(251, 196, 10);
  width: 20px;
  height: 60px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.card .text {
  padding: 15px;
  font-weight: normal;
  font-size: 1em;
}

.articles-container {
  padding: 40px;
  width: 70%;
}

.row {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
  flex-wrap: wrap;
}

.products-btn {
  background-color: #383865;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  border: none;
  border-radius: 10px;
  padding: 5px;
  color: white;
  font-weight: 700;
  padding: 1rem;
}

.p-5 {
  padding: 5px;
}

.vertical-timeline {
  margin-left: 40px !important;
}

@media only screen and (max-width: 880px) {
  .specialization {
    padding: 5px;
  }
  .specialization .grid {
    flex-direction: column !important;
    padding: 0px;
    width: 85%;
  }
  .grid .card {
    width: 90%;
    margin-top: 3rem;
    padding: 1rem;
  }
  .specialization button {
    width: 100%;
  }
  .articles-container {
    width: 90%;
    padding: 20px;
  }
  .row {
    margin-top: 50px !important;
    justify-content: center !important;
  }
  .purple-btn {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 1180px) {
  .specialization .grid {
    width: 90%;
  }
}

@media only screen and (max-width: 780px) {
  .learning_card_sublayout {
    padding: 10px !important;
  }
  .learning_path_layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .learning_path_card {
    width: 90%;
  }
  .card_title {
    font-size: 14px;
    margin-top: 10px;
  }
  .card_subtitle {
    font-size: 12px;
  }
  .card_footer {
    font-size: 16px;
  }
}

@media only screen and (min-width: 603px) and (max-width: 907px) {
  .learning_path_layout {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .learning_path_card {
    width: 60%;
  }
}

@media only screen and (min-width: 908px) and (max-width: 1158px) {
  .learning_path_layout {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .learning_path_card {
    width: 40%;
  }
}
